.landing-header {
  --color: var(--white);
  --logo-height: 32px;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: var(--z-index-header);

  flex-direction: column;

  justify-content: center;
  height: var(--header-height);

  pointer-events: none;
  transition: background-color 0.3s ease;
}
@media (--mobile) {
  .landing-header {
    --logo-height: 24px;
  }
}

.landing-header::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -100px;
  left: 0;

  content: '';

  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0%,
    ease-in-out,
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
}
@media (--mobile) {
  .landing-header::before {
    bottom: -32px;
  }
}

.landing-header .max-width {
  position: relative;

  display: flex;

  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.landing-header__logo {
  position: absolute;
  left: var(--page-left-padding);

  display: flex;
  align-items: center;
  height: var(--logo-height);

  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
}

.landing-header__logo img {
  display: block;
  align-items: center;
  width: auto;
  height: 100%;
  user-select: none;
}

.landing-header__ctas {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  pointer-events: auto;
}

.landing-header__cta {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;

  font-size: 16px;

  font-weight: 600;
  line-height: 1;
  color: var(--dark);
  text-decoration: none;

  border-radius: 5px;
  background-color: #fb706b;
  transition: 200ms background-color;
}
.landing-header__cta:hover {
  background-color: #ff9490;
}
.landing-header__cta:active {
  background-color: #fb706b;
}
@media (--mobile) {
  .landing-header__cta {
    padding: 14px 24px;
  }
}
